import React from "react"

import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql, useStaticQuery} from "gatsby";
import draftToHtml from "draftjs-to-html";

const TermsCondition = () => {
    const intl = useIntl()
    let data = useStaticQuery(graphql`
    query TermsQuery {
      allPage(sort: {fields: updated_at, order: DESC}, filter: {slug: {eq: "terms_condition"}, is_active: {eq: true}}) {
        nodes {
          title
          value
          updated_at
          lang
          slug
        }
      }
    }
    `)
    let val = data.allPage.nodes.filter(function (item) {
        return item.lang === intl.locale
    })
    val = val[0]

    return (
        <div className={"img-bg"}>
            <Layout>
                <SEO title="Terms Condition"/>
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <div className="shadow segment-table my-4 bg-white p-4">
                            <br/>
                            <h2 className="my-1 txt-primary text-center">
                                <b style={{color: '#ad1457'}}>{intl.formatMessage({ id: "terms-condition" })}</b>
                            </h2>
                            <br></br>
                            {
                                val === undefined ? (<div className={"container"}><p className={"text-center"}>{intl.formatMessage({ id: "not-found-content" })}</p></div>) : (
                                    <div dangerouslySetInnerHTML={{__html: (draftToHtml(JSON.parse(val.value)))}} />
                                )
                            }
                            <br/>
                        </div>
                    </div>
                </div>
                {/* <Link to="/">Go back to the homepage</Link> */}
            </Layout>
        </div>
    )
}

export default TermsCondition
